import React, { useState, useEffect } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/button";

function SignupPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = () => {
    window.analytics.identify(email, {
      first_name: firstName,
      last_name: lastName,
      email,
    });

    setSubmitted(true);
  };

  const [ajsAnonymousId, setAjsAnonymousId] = useState("");
  //const [ampDeviceId, setAmpDeviceId] = useState("");

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      setAjsAnonymousId(window.analytics.user().anonymousId());
      //setAmpDeviceId(window.amplitude.options.deviceId); // cloud mode
      //setAmpDeviceId(window.amplitude.getInstance().getDeviceId())
    }
  }, []);

  const handleSecretButtonClick = async () => {
    window.analytics.track("secret clicked", {});
    window.analytics.track("signup started", {});

    // POST request using fetch with async/await
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ajs_anonymous_id: ajsAnonymousId,
        url: window.location.href,
        first_name: firstName,
        last_name: lastName,
        email

      }),
    };
    const response = await fetch(
      "https://spectatin-node-segment-test.herokuapp.com/signup",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
  };
  return (
    <Layout>
      <SEO keywords={[`spectatin signup`]} title="Signup" />
      <section className="mt-12">
        <form className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <label
            // className="block mb-2 text-xs font-bold uppercase"
            className="text-lg leading-6 font-medium text-gray-900"
            htmlFor="first-name"
          >
            First Name
          </label>

          <input
            className="w-full mb-6 form-input"
            id="first-name"
            placeholder="Bill"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />

          <label
            className="text-lg leading-6 font-medium text-gray-900"
            htmlFor="last-name"
          >
            Last Name
          </label>

          <input
            className="w-full mb-6 form-input"
            id="last-name"
            placeholder="Murray"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />

          <label
            className="text-lg leading-6 font-medium text-gray-900"
            htmlFor="email"
          >
            Email
          </label>

          <input
            className="w-full mb-6 form-input"
            id="email"
            placeholder="bill@murray.com"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button onClick={() => handleSubmit()}>
            {submitted ? "Thanks!" : "Submit"}
          </Button>
          {submitted && firstName === "test" ? (
            <div className="">
              <Button onClick={() => handleSecretButtonClick()}>
                Continue secret account creation...
              </Button>
              {/* <a href="https://jamies-fabulous-project-9f8a08.webflow.io/">webflow referrer test</a> */}
            </div>
          ) : null}
        </form>
      </section>
    </Layout>
  );
}

export default SignupPage;
